@tailwind base;
@tailwind components;
@tailwind utilities;

/* React datepicker overrides */
.react-datepicker {
  border: none;
}

.react-datepicker__header {
  background: none;
}

.react-datepicker__day--selected {
  background: #1da765;
}

.schedule-calendar-wrapper .react-datepicker__month-container {
  border-width: 1px;
  border-color: rgba(206, 209, 212, 1);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.schedule-calendar-wrapper .react-datepicker__navigation {
  top: 0.5rem;
}

.async-select input:focus {
  box-shadow: none;
}

:root {
  --rt-color-white: #fff;
  --rt-color-dark: #222;
  --rt-color-success: #8dc572;
  --rt-color-error: #be6464;
  --rt-color-warning: #f0ad4e;
  --rt-color-info: #337ab7;
  --rt-opacity: 0.9;
  --rt-transition-show-delay: 0.15s;
  --rt-transition-closing-delay: 0.15s;
}

.core-styles-module_tooltip__3vRRp {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
  will-change: opacity;
}

.core-styles-module_fixed__pcSol {
  position: fixed;
}

.core-styles-module_arrow__cvMwQ {
  position: absolute;
  background: inherit;
}

.core-styles-module_noArrow__xock6 {
  display: none;
}

.core-styles-module_clickable__ZuTTB {
  pointer-events: auto;
}

.core-styles-module_show__Nt9eE {
  opacity: var(--rt-opacity);
  transition: opacity var(--rt-transition-show-delay) ease-out;
}

.core-styles-module_closing__sGnxF {
  opacity: 0;
  transition: opacity var(--rt-transition-closing-delay) ease-in;
}

/** end - core styles **/

.styles-module_tooltip__mnnfp {
  padding: 8px 16px;
  border-radius: 3px;
  font-size: 90%;
  width: max-content;
}

.styles-module_arrow__K0L3T {
  width: 8px;
  height: 8px;
}

[class*="react-tooltip__place-top"] > .styles-module_arrow__K0L3T {
  transform: rotate(45deg);
}

[class*="react-tooltip__place-right"] > .styles-module_arrow__K0L3T {
  transform: rotate(135deg);
}

[class*="react-tooltip__place-bottom"] > .styles-module_arrow__K0L3T {
  transform: rotate(225deg);
}

[class*="react-tooltip__place-left"] > .styles-module_arrow__K0L3T {
  transform: rotate(315deg);
}

/** Types variant **/
.styles-module_dark__xNqje {
  background: var(--rt-color-dark);
  color: var(--rt-color-white);
}

.styles-module_light__Z6W-X {
  background-color: var(--rt-color-white);
  color: var(--rt-color-dark);
}

.styles-module_success__A2AKt {
  background-color: var(--rt-color-success);
  color: var(--rt-color-white);
}

.styles-module_warning__SCK0X {
  background-color: var(--rt-color-warning);
  color: var(--rt-color-white);
}

.styles-module_error__JvumD {
  background-color: var(--rt-color-error);
  color: var(--rt-color-white);
}

.styles-module_info__BWdHW {
  background-color: var(--rt-color-info);
  color: var(--rt-color-white);
}

@import "./primereact-theme-seadragon.css";
@import "primereact/resources/primereact.min.css";
@import "primeicons/primeicons.css";

/* Extend the container class on large screens */
@media screen and (min-width: 1536px) {
  .container {
    max-width: 2000px;
  }
}
